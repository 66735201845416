body {
	font-family: 'Zilla-Light';
	&.node-type-page,
	&.node-type-webform,
	&.node-type-pdf-page,
	&.page {
		.container {
			max-width: 830px;
		}
		.l-header {
			.container {
				max-width: 1196px;
			}
		}
	}
	&.front {
		.container {
			max-width: 1196px;
		}
		.block-views-slideshow-block {
			.container {
				max-width: unset;
			}
		}
	}
	.l-footer {
		.container {
			max-width: 900px;
			.block--inner-wrapper {
				padding: 0;
			}
		}
	}
}

h1, h2, h3, h4, h5, h6 {
	font-weight: normal;	
}

h1,
h2 {
	font-family: 'Zilla-Medium';
}
h3, h4, h5 {
	font-family: 'Zilla-Semibold';
}
h1 {
	font-size: $font-x-lg;
	line-height: 42px;
}
h2 {
	font-size: $font-lg;
	margin: 65px 0 25px;
}
h3 {
	font-size: $font-md;
	margin: 50px 0 25px;
}
h4 {
	font-size: $font-sm;
	line-height: 30px;
}
h5 {
	font-size: $font-x-tiny;
}
hr {
	border-top: 2px solid $reef-gold;
	margin: 65px 0;
	@include md {
		margin: 30px 0;
	}
}
a {
	font-family: 'Zilla-Semibold';
	@include sm {
		line-break: auto;
	}
}
p {
	font-size: $font-sm;
	margin-bottom: 25px;
}
img {
	&.align-left {
		margin: 0 15px 15px 0;
	}
}
b,
strong {
	font-family: 'Zilla-Semibold';
	font-weight: normal;
}
span[dir="ltr"] {
	display: inline-block;
	text-align: left;
}
.container {
	max-width: 1196px;
}
.l-page-title {
	padding-top: 0;
}
.page-title {
	margin: 75px 0 33px;
}
.block-title {
	font-size: $font-x-lg;
	line-height: 42px;
}
.field-name-field-pdf-file {
	background: #414141;
}
.layout-flexible-content,
.layout--moscone-flipped {
	overflow: hidden;
	padding-bottom: 0;
}
.right {
	text-align: right;
}
.link_red {
	color: $carmine !important;
	display: block;
	text-align: right;
}
.no_margin_top {
	margin-top: 0 !important;
}
.no_margin {
	margin: 0;
}
.no_page_title {
	margin-top: 75px;
}
.hidden {
	display: none;
}
@include md {
	h1,
	.page-title {
		font-size: $font-x-md;
		line-height: 30px;
		margin: 30px 0;
	}
	h2 {
		font-size: $font-md;
		margin: 30px 0 25px;
	}
	h3 {
		font-size: $font-sm;
	}
	p {
		font-size: $font-x-tiny;
		margin-bottom: 15px;
	}
	.no_page_title {
		margin-top: 30px;
	}
}
.l-wrapper {
	ul,
	ol {
		margin-bottom: 33px;
		li {
			font-size: $font-sm;
			line-height: 30px;
		}
		&.slides,
		&.flex-direction-nav {
			margin-bottom: 0;
		}
	}
}
.front {
	p {
		font-size: $font-md;
	}
	.l-wrapper {
		ul,
		ol {
			li {
				font-size: $font-md;
			}
		}
	}
	.no_page_title {
		margin: 0;
	}
	@include md {
		p {
			font-size: $font-x-tiny;
		}
		.l-wrapper {
			ul,
			ol {
				li {
					font-size: $font-x-tiny;
				}
			}
		}
	}
}
nav {
	&.tabs {
		margin: 15px 0;
		padding: 10px;
		&:before {
			height: 100%;
		}
		ul,
		ol {
			margin: 0;
			padding: 0;
			li {
				font-size: $font-tiny;
				&:first-child,
				&:last-child {
					a {
						border-radius: 0;
					}
				}
			}
		}
	}
	@include md {
		&.tabs {
			ul,
			ol {
				li {
					width: 100%;
					a {
						border-right: 0;
						padding: 10px;
					}
					&:first-child {
						a {
							border-right: 0;
						}
					}
				}
			}
		}
	}
}
.layout {
	.l-messages {
		margin: 33px 0;
	}
}
.opera_outreach {
	border-bottom: 2px dotted $reef-gold;
	border-top: 2px solid $reef-gold;
	margin: 33px 0;
	padding: 33px 0;
	p {
		display: table;
		font-family: 'Zilla-Semibold';
		font-size: $font-md;
		margin: 0;
		width: 100%;
		strong {
			float: left;
			padding-right: 15px;
		}
		span {
			color: $roti;
			float: right;
			font-size: $font-sm;
			line-height: 30px;
		}
	}
	@include md {
		margin: 15px 0;
		padding: 15px 0;
		p {
			font-size: $font-md;
		}
	}
}
.block {
	&.apply_by_mail {
		border-top: 2px solid $reef-gold;
		margin-top: 30px;
		padding: 30px 0;
		h3 {
			margin: 0 0 30px;
			text-align: center;
			a {
				color: $roti;
			}
		}
	}
}
.link_label_wrapper {
	display: table;
	margin: 0;
	width: 100%;
	.link_label {
		color: $roti;
		float: right;
	}
}
.field-name-body {
	padding: 0;
	h3 {
		font-size: $font-lg;
	}
	@include md {
		h3 {
			font-size: $font-md;
			margin: 30px 0 15px;
		}
		table {
			tr {
				display: grid;
				grid-template-columns: 50% 50%;
			}
		}
	}
}
.board_member_wrapper {
	display: table;
	width: 100%;
	padding-top: 20px;
	h3 {
		color: $roti;
		font-size: $font-x-md;
		margin: 0 0 33px;
	}
	.board_member_left {
		float: left;
		padding-right: 15px;
		width: 33.333333%;
	}
	.board_member_right {
		float: left;
		width: 62.666666%;
		margin-left: 4%;
		ul {
			display: block;
			column-width: 200px;
			column-gap: 15px;
			list-style-type: none;
			margin-bottom: 0;
			padding: 0;
			width: 100%;
		}
	}
	@include md {
		h3 {
			font-size: $font-md;
			margin-bottom: 15px;
		}
		.board_member_left {
			padding-right: 0;
			width: 100%;
		}
		.board_member_right {
			margin-top: 40px;
			width: 100%;
			margin-left: 0;
			ul {
				column-width: auto;
			}
		}
		h3 br {
			display: none;
		}
	}
}
.sites_of_interest {
	h3 {
		margin: 0 0 30px;
	}
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			border-top: 2px dotted $gray;
			padding: 10px 0;
			a {
				color: $reef-gold;
				font-family: 'Zilla-Semibold';
			}
			&:last-child {
				border-bottom: 2px dotted $gray;
			}
		}
	}
	> p {
		&:last-child {
			margin-bottom: 0;
		}
	}
	a {
		&.button {
			background: $bay-of-many;
			color: $white;
			font-size: $font-x-sm;
			line-height: 30px;
			margin: 0;
			padding: 10px 30px;
		}
	}
	img {
		width: 100%;
	}
	@include md {
		a {
			&.button {
				font-size: $font-x-tiny;
			}
		}
	}
}
.view-board-meetings {
	table {
		font-size: $font-sm;
		line-height: 30px;
		margin-bottom: 0;
		tr {
			border-bottom: 2px dotted $gray;
			padding: 0;
		}
		th {
			background: none;
			border-right: none;
			border-bottom: none;
			color: $gray;
			&.views-field-title {
				padding-left: 0;
			}
		}
		td {
			border: none;
			&.views-field-title {
				padding-left: 0;
				a {
					color: $black;
					font-family: 'Zilla-Medium';
				}
			}
		}
	}
	@include md {
		table {
			display: block;
			width: 100%;
			tbody,
			thead {
				display: block;
				width: 100%;
				tr,
				th,
				td {
					display: block;
					padding: 5px 0;
					width: 100%;
				}
			}
		}
	}
}
.crm-container {
	h3 {
		background: no-repeat;
		font-family: 'Zilla-Semibold';
		font-size: 24px;
		margin: 0;
		padding: 0 0 30px;
		a {
			color: $roti !important;
			float: right;
		}
	}
	@include md {
		h3 {
			display: table;
			font-size: $font-x-tiny;
			padding: 0;
			width: 100%;
		}
	}
}