.crm-container {
	.crm-submit-buttons,
	.action-link {
		height: auto;
		margin: 10px 0;
		padding: 5px 0;
	}
	&.ui-dialog {
		z-index: 9999 !important;
		.ui-dialog-content {
			margin: 30px auto;
			max-width: unset;
			pointer-events: auto;
			width: 100%;
		}
	}
	.two {
		width: auto;
	}
}

.crm-contribution-page-id-5 {
	.crm-price-amount-label {
		display: table-cell;
		width: 200px;
	}
	.crm-price-amount-amount {
		font-family: 'Zilla-Semibold';
	}
}

#civicrm-footer {
	border-bottom: 1px solid #ddd;
}

#crm-main-content-wrapper {
	p, li {
		font-size: 20px;
		line-height: 1.6;
	}

}
.event_description-section {
	p {
		img {
			border: 1px solid #dee2ea;
			margin: 0 10px 10px 0;
			padding: 10px;
			width: calc(25% - 15px);
		}
	}
	@include md {
		p {
			img {
				width: calc(50% - 15px);
			}
		}
	}
}