.ui-accordion {
	.ui-accordion-header {
		background: no-repeat;
		border-top: 2px dotted $gray;
		font-size: $font-sm;
		line-height: 30px;
		margin: 0;
		padding: 21px 20px;
		position: relative;
		a {
			color: $black;
			padding-left: 5px;
		}
		.ui-icon {
			background-image: url(../images/green-carrot.png);
			background-position: center;
			background-size: contain;
			filter: grayscale(143%);
			height: 10px;
			left: 0;
			position: absolute;
			top: 37px;
			transition: 0.5s;
			width: 10px;
		}
		&.ui-state-active {
			border-bottom:0;
			.ui-icon {
				background-image: url(/core/misc/ui/images/ui-icons_444444_256x240.png);
			}
		}
		&.active {
			border-bottom: 2px dotted $gray;
		}
		&:focus {
			outline: none;
		}
		&:nth-last-child(2) {
			border-bottom: 2px dotted $gray;
		}
		&.ui-accordion-header-active {
			.ui-icon {
				background-image: url(../images/green-carrot.png);
				background-position: center;
				background-size: contain;
				transform: rotate(90deg);
			}
		}
	}
	.ui-accordion-content {
		background: #FBF8EF;
		border: 0;
		font-size: $font-sm;
		line-height: 30px;
		padding: 25px;
		.field-items {
			.field-item {
				margin-bottom: 15px;
				.content {
					display: table;
					width: 100%;
					.field {
						display: table-cell;
						p {
							margin: 0;
						}
						&.field-name-field-title {
							font-family: 'Zilla-Semibold';
							width: 100px;
						}
					}
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		&:last-child {
			border-bottom: 2px dotted $gray;
		}
	}
	@include md {
		.ui-accordion-header {
			font-size: $font-x-tiny;
			line-height: 23px;
			a {
				padding-left: 0;
			}
			.ui-icon {
				top: 32px;
			}
		}
		.ui-accordion-content {
			font-size: $font-x-tiny;
			line-height: 23px;

			.field-items {
				.field-item {
					.content {
						.field {
							&.field-name-field-title {
								width: 50px;
							}
						}
					}
				}
			}
		}
	}
}
#node-11 {
	margin-top: 10px;
	.ui-accordion {
		.ui-accordion-header {
			border-color: $reef-gold;
			font-size: $font-md;
			text-align: center;
			a {
				color: $reef-gold;
			}
		}
		.ui-accordion-content {
			border-color: $reef-gold;
		}
	}
	@include md {
		.ui-accordion {
			.ui-accordion-header {
				font-size: $font-x-tiny;
			}
		}
	}
}
.block-views-grants-block {
	.ui-accordion {
		.ui-accordion-header {
			border-color: $reef-gold;
		}
	}
}