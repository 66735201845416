article {
	&.node-page,
	&.node-webform {
		.webform-client-form {
			.webform-component {
				display: table;
				margin-bottom: 30px;
				width: 100%;
				> label {
					font-family: 'Zilla-Semibold';
					font-size: $font-x-tiny;
					float: left;
					margin: 0;
					padding-right: 15px;
					text-align: right;
					width: 200px;
				}
				> input {
					background: $merino;
					border: 0;
					float: left;
					margin: 0;
					text-align: left;
					width: calc(100% - 200px);
				}
				> select {
					border: 1px solid $black;
					float: left;
					margin: 0;
					text-align: left;
				}
				> .description {
					clear: both;
					float: right;
					font-family: 'Zilla-Light';
					font-size: $font-tiny;
					line-height: 22px;
					margin: 5px 0 0;
					width: calc(100% - 200px);
				}
				> .form-wrapper {
					font-family: 'Zilla-Light';
					font-size: $font-x-tiny;
					float: left;
					line-height: 26px;
					text-align: left;
					width: calc(100% - 200px);
				}
				.webform-datepicker {
					> .form-item {
						display: inline-block;
						padding: 0;
						select {
							border: 1px solid $black;
						}
					}
					.webform-calendar {
						height: 40px;
					}
				}
				.form-textarea-wrapper {
					font-family: 'Zilla-Light';
					font-size: $font-x-tiny;
					float: left;
					line-height: 26px;
					text-align: left;
					width: calc(100% - 200px);
					textarea {
						border: 1px solid $black;
						border-bottom: 0;
					}
					.grippie {
						background: $merino url(../images/grippie.png) no-repeat;
						background-position: center;
						border: 1px solid $black;
						border-top: 0;
						height: 15px;
					}
				}
				&.webform-component-textarea,
				&.webform-component-radios {
					label {
						margin: 0;
					}
				}
				&.webform-component--state,
				&.webform-component--zip,
				&.webform-component--daytime-phone,
				&.webform-component--evening-phone,
				&.webform-component---of-students,
				&.webform-component---of-adults,
				&.webform-component--cost-of-producing-program {
					float: left;
					width: 50%;
				}
			}
			.form-actions {
				clear: both;
				margin: 15px 0 0;
				input {
					background: $bay-of-many;
					font-family: 'Zilla-Semibold';
					font-size: $font-sm;
					line-height: 30px;
					margin: 0 0 0 200px;
					padding: 0 50px;
				}
			}
		}
		@include md {
			.webform-client-form {
				.webform-component {
					label {
						margin-bottom: 10px;
						padding: 0;
						text-align: left;
						width: 100%;
					}
					input {
						width: 100%;
					}
					select {
						width: 100%;
					}
					.description {
						width: 100%;
					}
					.form-wrapper {
						width: 100%;
					}
					.form-textarea-wrapper {
						width: 100%;
					}
					.webform-datepicker {
						.webform-calendar {
							width: auto;
						}
					}
					&.webform-component--state,
					&.webform-component--zip,
					&.webform-component--daytime-phone,
					&.webform-component--evening-phone,
					&.webform-component---of-students,
					&.webform-component---of-adults,
					&.webform-component--cost-of-producing-program {
						float: left;
						width: 100%;
					}
					&.webform-component-textarea,
					&.webform-component-radios {
						label {
							margin-bottom: 10px;
						}
						.form-type-radio {
							input {
								width: auto;
							}
						}
					}
				}
				.form-actions {
					input {
						margin: 0;
						width: 100%;
					}
				}
			}
		}
	}
}

#crm-main-content-wrapper {
	form {
		input[type='checkbox'],
		input[type='radio'] {
			min-width: unset !important;
		}
		select {
			background: no-repeat;
			border-radius: 0 !important;
		}
		fieldset {
			border-top: 0;
			margin: 0;
			padding: 0;
			width: 100%;
			legend {
				float: left;
				font-family: 'Zilla-Semibold';
				font-size: $font-x-tiny;
				margin: 10px 0;
				padding: 0;
				width: 100%;
			}
			&.honor_block-group {
				border-bottom: 2px dotted $roti;
				border-top: 2px dotted $roti;
				margin: 25px 0 65px;
				padding: 10px 0;
				.content {
					margin: 0;
				}
			}
		}
		.header-dark {
			background: $alpine;
			border-radius: 0;
			font-family: 'Zilla-Semibold';
			font-size: $font-x-tiny;
			font-weight: normal;
		}
		.display-block {
			margin: 15px 0;
			table {
				tr {
					th {
						font-family: 'Zilla-Semibold';
					}
					th,
					td {
						color: $black;
						font-size: $font-x-tiny;
						font-weight: normal;
					}
				}
			}
		}
		.label {
			font-family: 'Zilla-Semibold';
			font-size: $font-x-tiny !important;
			padding: 0 !important;
			text-align: right;
		}
		.content {
			font-size: $font-x-tiny;
			padding: 0 !important;
			input {
				background: $merino;
				border: 0;
				border-radius: 0 !important;
				min-width: 150px;
			}
		}
		.crm-section {
			clear: both;
			padding: 10px 0;
			&.custom_post-section,
			&#onBehalfOfOrg {
				padding: 0;
			}
		}
		.custom_pre-section {
			&.crm-section {
				padding: 0;
			}
		}
		.crm-public-form-item {
			.label {
				font-family: 'Zilla-Semibold';
				font-size: $font-x-tiny;
			}
			.content {
				input {
					background: $merino;
					border: 0;
					border-radius: 0;
				}
				.select2-container {
					> a {
						background: no-repeat;
						border-radius: 0;
						border: 1px solid $black;
						.select2-arrow {
							background: $merino;
							border: 0;
							border-radius: 0;
						}
					}
				}
			}
		}
		#priceset {
			padding: 0;
			.label {
				display: block !important;
				font-family: 'Zilla-Semibold';
				font-size: $font-x-tiny;
			}
			.content {
				input {
					background: $merino;
					border: 0;
					border-radius: 0;
				}
			}
			#pricesetTotal {
				padding: 10px 0;
				.content {
					display: block !important;
				}
			}
		}
		#billing-payment-block {
			#payment_information {
				fieldset {
					> .crm-section {
						padding: 0;
						.label {
							display: block !important;
							font-family: 'Zilla-Semibold';
							font-size: $font-x-tiny;
						}
						.content {
							input {
								background: $merino;
								border: 0;
								border-radius: 0;
							}
							.select2-container {
								> a {
									background: no-repeat;
									border-radius: 0;
									border: 1px solid $black;
									.select2-arrow {
										background: $merino;
										border: 0;
										border-radius: 0;
									}
								}
							}
						}
					}
				}
			}
		}
		#crm-submit-buttons {
			display: inline-table;
			margin: 0 !important;
			padding: 10px 0 10px 19%;
			.crm-form-submit {
				background: $bay-of-many;
				border: 0;
				border-radius: 0;
				font-family: 'Zilla-Semibold';
				font-size: $font-sm;
				line-height: 30px;
				margin: 0 15px 0 0;
				padding: 10px 15px;
				i {
					font-size: $font-x-tiny;
				}
			}
		}
		&.CRM_Contribute_Form_Contribution_Main {
			.crm-section {
				&.cms_user-section,
				&.premium_block-section,
				&.honor_block_text-section {
					padding: 0;
				}
			}
			fieldset {
				&.honor_block-group {
					legend {
						margin: 0;
					}
				}
			}
			#footer_text {
				padding-top: 0;
				h3 {
					padding: 0 0 65px;
					text-align: center;
					a {
						float: none;
					}
				}
			}
		}
	}
	@include md {
		form {
			input {
				padding: 5px 0 !important;
				width: 100%;
				&[type="radio"],
				&[type="checkbox"] {
					width: auto;
				}
			}
			.select2-container {
				width: 100% !important;
			}
			.content {
				margin: 0;
				width: 100%;
			}
			.label {
				margin-bottom: 10px;
				text-align: left;
				width: 100%;
			}
			#priceset {
				.label {
					text-align: left;
					width: 100%;
				}
			}
			#crm-submit-buttons {
				padding: 10px 0;
				.crm-form-submit {
					margin: 0 0 15px 0;
					width: 100%;
					&:last-child {
						margin: 0;
					}
				}
			}
		}
	}
}
#crm-container {
	&.crm-public {
		.content {
			font-size: $font-x-tiny;
		}
	}
}
.crm-public-footer {
	a {
		.empowered-by-logo {
			position: static;
		}
	}
}

input.form-submit, button.form-submit, .button {
	background: #23408f;
	display: inline-table;
	border: 0;
	border-radius: 0;
	font-family: 'Zilla-Semibold';
	font-size: 20px;
	line-height: 30px;
	margin: 0px 10px 30px;
	padding: 10px 15px;
	text-transform: capitalize;
	min-width: 200px;
	i {
		font-size: $font-x-tiny;
	}
	
}
