#donation-node-form {
	input {
		background: $merino;
		border: 0;
		width: 100%;
		&.form-text {
			margin: 0;
		}
	}
	select {
		border: 1px solid $black;
	}
	.field-name-field-contribution-amount {
		input {
			max-width: 300px;
		}
	}
	.field-name-field-honoree-options {
		border-bottom: 2px dotted $gray;
		border-top: 2px dotted $gray;
		clear: both;
		margin-bottom: 80px;
		padding: 15px 0;
		.form-item {
			margin: 0;
		}
		.form-radios {
			display: table;
			width: 100%;
			.form-item {
				float: left;
				margin-right: 15px;
				input {
					width: auto;
				}
				&:first-child {
					display: none;
				}
			}
		}
		.description {
			font-size: $font-tiny;
			margin: 0;
		}
	}
	.field-name-field-first-name {
		float: left;
		position: relative;
		width: 30%;
		&:before {
			content: "Your Name and Address";
			font-size: $font-tiny;
			font-weight: normal;
			position: absolute;
			top: -35px;
		}
	}
	.field-name-field-last-name {
		float: left;
		padding-left: 15px;
		width: 70%;
	}
	.field-name-field-address {
		clear: both;
		fieldset {
			border: 0;
			margin: 0;
			padding: 0;
			> legend {
				display: none;
			}
			.fieldset-wrapper {
				padding: 0;
			}
		}
	}
	.field-name-field-phone {
		input {
			max-width: 300px;
		}
	}
	.vertical-tabs {
		display: none;
	}
	#edit-submit {
		background: $bay-of-many;
		width: auto;
	}
	#addressfield-wrapper {
		.form-item-field-address-und-0-postal-code {
			clear: both;
		}
	}
	@include md {
		input {
			max-width: unset;
			width: 100%;
		}
		.field-name-field-contribution-amount {
			input {
				max-width: unset;
			}
		}
		.field-name-field-first-name {
			width: 100%;
		}
		.field-name-field-last-name {
			padding: 0;
			width: 100%;
		}
		.field-name-field-phone {
			input {
				max-width: unset;
				width: 100%;
			}
		}
		.field-type-addressfield {
			.fieldset-wrapper {
				> .form-item {
					select {
						width: 100%;
					}
				}
			}
			.addressfield-container-inline {
				select {
					width: 100%;
				}
				> .form-item  {
					margin-right: 0;
					width: 100%;
				}
			}
		}
		#edit-submit {
			margin-right: 0;
			width: 100%;
		}
	}
}