.block {
	&.block-views-grants-block  {
		padding-top: 0;
	}
}
.view-grants {
	.view-header {
		display: table;
		margin-bottom: 30px;
		width: 100%;
		p {
			float: left;
			margin: 0;
			width: calc(100% - 100px);
			&:last-child {
				text-align: right;
				width: 100px;
			}
		}
	}
	.view-filters {
		display: none;
		.views-exposed-form {
			.bef-select-as-links {
				> .form-item  {
					border-bottom: 1px solid $black;
					display: flex;
					margin-bottom: 50px;
					padding-bottom: 0;
					> .form-item  {
						text-align: center;
						padding: 0;
						width: 50%;
						a {
							background: $white;
							border: 1px solid $black;
							border-top: 0;
							color: #DDDDDD;
							display: block;
							font-size: $font-sm;
							font-weight: normal;
							line-height: 30px;
							margin-bottom: -1px;
							padding: 5px 15px;
							&:hover {
								color: $black;
								text-decoration: none;
							}
							&.active {
								border-top: 1px solid $black;
								border-bottom: 0;
								color: $black;
							}
						}
						&:first-child {
							a {
								border-left: 0;
							}
						}
						&:last-child {
							a {
								border-right: 0;
							}
						}
						&:first-child {
							a {
								border-right: 1px solid $black;
							}
						}
					}
				}
			}
		}
	}
	.view-content {
		.ui-accordion-content {
			.views-accordion-item {
				display: table;
				margin-bottom: 15px;
				width: 100%;
				.views-field-title {
					float: left;
					width: calc(100% - 100px);
				}
				.views-field-field-grades {
					float: left;
					text-align: right;
					width: 100px;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	@include md {
		.view-content {
			.ui-accordion-content {
				.views-accordion-item {
					.views-field-title {
						width: calc(100% - 50px);
					}
					.views-field-field-grades {
						width: 50px;
					}
				}
			}
		}
	}
}

.ui-accordion {
	.ui-accordion-header {
		&.views-accordion-grants-block-header{
			.ui-icon-triangle-1-s {
				transform: rotate(0deg) !important;
			}
			&.active {
				.ui-icon-triangle-1-e,
				.ui-icon-triangle-1-s  {
					transform: rotate(90deg) !important;
				}
			}
			&.open {
				border-bottom:0;
			}
		}
	}
}

.view-grants {
	.view-header {
		p {
			margin: 0;
		    width: 100% !important;
			text-align: left !important;
		}
	}
}