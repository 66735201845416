// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}
// Medium devices
@mixin min-md {
  @media (min-width: #{$screen-md-max}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: #{$screen-xl-max}) {
    @content;
  }
}
@mixin xxl {
  @media (max-width: #{$screen-xxl-max}) {
    @content;
  }
}