// User menu
.block-system-user-menu {
	.block-content {
		display: table;
		width: 100%;
		> ul {
			display: inline-block;
			float: right;
			padding: 42px 0 18px;
			text-transform: uppercase;
			> li {
				border: 0;
				padding: 0;
				> a {
					font-family: 'Zilla-Medium';
					font-size: $font-sm;
					border-right: 1px solid $roti;
					color: $roti;
					padding: 0 20px;
					&:hover {
						background: no-repeat;
						padding: 0 20px;
					}
				}
				&:last-child {
					a {
						border: 0;
						padding-right: 0;
					}
				}
				@include md {
					&:last-child {
						a {
							padding-right: 20px;
						}
					}
				}
				&:hover {
					background: no-repeat;
				}

			}
		}
	}
}

// Main navigation/menu
.block-system-main-menu {
	.block-content {
		display: table;
		width: 100%;
		> ul {
			display: inline-block;
			float: right;
			> li {
				border: 0;
				padding: 4px 0;
				> a {
					background: no-repeat !important;
					font-family: 'Zilla-Medium';
					font-size: 21px;
					padding: 0 15px;
					transition: 0.5s;
					&:hover {
						color: $carmine;
						padding: 0 15px;
					}
					&.highlighted {
						border: 0 !important;
					}
					&.active {
						color: $carmine;
					}
				}
				&:hover {
					background: no-repeat;
					filter: brightness(100%);
				}
				&:last-child {
					padding-bottom: 10px;
					a {
						padding-right: 0;
					}
				}
				&.has-children {
					> a {
						padding: 0 15px;
						.sub-arrow {
							display: none;
						}
					}
					> ul {
						-webkit-box-shadow: 0px 30px 25px -6px rgba(0,0,0,0.25);
						-moz-box-shadow: 0px 30px 25px -6px rgba(0,0,0,0.25);
						box-shadow: 0px 30px 25px -6px rgba(0,0,0,0.25);
						background: no-repeat;
						border: 0;
						font-family: 'Zilla-Medium';
						padding-top: 39px;
						> li {
							background: $white;
							border: 0;
							> a {
								background: no-repeat;
								color: $black;
								font-size: $font-x-tiny;
								padding: 10px 15px;
								&:hover {
									color: $carmine;
								}
								&.active {
									color: $carmine;
								}
							}
							&:first-child {
								padding-top: 15px;
							}
							&:last-child {
								padding-bottom: 15px;
							}
						}
					}
				}
			}
		}
	}
	@include lg {
		.block-content {
			> ul {
				> li {
					> a {
						padding: 0 10px;
					}
					&.has-children {
						> a {
							padding: 0 10px;
						}
					}
					&:first-child {
						> a {
							padding-left: 0;
						}
					}
				}
			}
		}
	}
	@include md {
		.block-content > ul > li:first-child > a {
			padding-left: 10px;
			margin-bottom: 10px;
		}
	}
}