@font-face {
  font-family: 'Zilla-Light';
  src: URL('../fonts/Zilla_Slab/ZillaSlab-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Zilla-Medium';
  src: URL('../fonts/Zilla_Slab/ZillaSlab-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Zilla-Semibold';
  src: URL('../fonts/Zilla_Slab/ZillaSlab-SemiBold.ttf') format('truetype');
}