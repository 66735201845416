.l-header {
	-webkit-box-shadow: 0px 6px 25px -6px rgba(0,0,0,0.25);
	-moz-box-shadow: 0px 6px 25px -6px rgba(0,0,0,0.25);
	box-shadow: 0px 6px 25px -6px rgba(0,0,0,0.25);
	height: 150px;
	position: fixed;
	transition: 0.5s;
	top: 0;
	width: 100%;
	z-index: 999;
	.l-header-inner {
		display: table;
		padding: 13px 15px;
		.block-system-header {
			float: left;
			width: 400px;
			> .container {
				padding: 0;
			}
			.block-content {
				display: block;
				.header-identity-wrapper {
					padding: 15px 0;
					position: relative;
					.header-site-name-wrapper {
						position: inherit;
						z-index: 1;
						a {
							background: no-repeat;
							padding: 0 0 10px;
							.header-logo-wrapper {
								height: auto;
								margin: 0;
								max-height: unset;
								img {
									height: auto;
									max-height: unset;
									max-width: unset;
									width: 100%;
								}
							}
						}
					}
					.header-site-slogan {
						bottom: 35px;
						font-size: $font-sm;
						font-style: italic;
						height: auto;
						left: calc(100% + 15px);
						line-height: 30px;
						padding: 0;
						position: absolute;
						white-space: nowrap;
						z-index: 0;
						@include xl {
							bottom: 20px;
							left: 65px;
						}
					}
				}
			}
		}
		.block-system-user-menu {
			float: right;
			width: calc(100% - 400px);
		}
		.block-system-main-menu {
			clear: none;
			float: right;
			width: calc(100% - 400px);
		}
	}
	&.sticky_header {
		top: 0;
	}
	@include lg {
		.l-header-inner {
			.block-system-header {
				width: 375px;
			}
			.block-system-user-menu,
			.block-system-main-menu {
				width: calc(100% - 375px);
			}
		}
	}
	@include md {
		.l-header-inner {
			display: flex;
			display: -webkit-flex;
			flex-wrap: wrap;
			margin: 0 auto;
			max-width: 325px !important;
			padding: 0 15px;
			position: relative;
			.block-system-header {
				-webkit-order: 2;
				order: 2;
				width: 100%;
				.block-content {
					.header-identity-wrapper  {
						.header-site-name-wrapper {
							width: 100%;
						}
						.header-site-slogan  {
							bottom: 15px;
							left: -15px;
							width: 100vw;
						}
					}
				}
			}
			.block-system-user-menu {
				-webkit-order: 1;
				order: 1;
				width: 100%;
				.block--inner-wrapper {
					padding: 0;
					.block-content {
						> ul {
							padding: 15px 0 0;
							text-align: center;
							width: 100%;
							> li {
								display: inline-block;
							}
						}
					}
				}
			}
			.block-system-main-menu {
				-webkit-order: 3;
				order: 3;
				height: 100%;
				position: absolute;
				right: 15px;
				top: 0;
				width: 42px;
				z-index: 9;
				.block--inner-wrapper {
					display: table;
					height: 100%;
					padding-right: 0;
					padding-left: 12px;
					.block-content {
						display: table-cell;
						vertical-align: middle;
						.menu-toggle-button {
							height: 30px;
							margin: 0;
							position: absolute;
							top: calc(50% - 2px);
							width: 30px;
							.menu-toggle-button-icon {
								background: $white;
								&:before,
								&:after {
									background: $white;
								}
							}
							.menu-toggle-button-text {
								display: none;
							}
						}
						> .menu-dropdown {
							background: $bay-of-many;
							border-top: 2px solid $black;
							left: 0;
							padding: 15px 15px;
							position: fixed;
							width: 100%;
							> li {
								padding: 10px 0;
								&.has-children {
									> ul {
										box-shadow: none;
										display: block;
										padding: 0 0 0 15px;
										> li {
											background: $bay-of-many;
											a {
												color: $white;
											}
											&:first-child {
												padding: 0;
											}
											&:last-child {
												padding: 0;
												a {
													padding-bottom: 0;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		&.sticky_header {
			.l-header-inner {
				.block-system-main-menu {
					.block--inner-wrapper {
						.block-content {
							> .menu-dropdown {
								margin-top: -33px;
							}
						}
					}
				}
			}
		}
	}
	@include sm {
		.l-header-inner {
			.block-system-header {
				.block-content {
					.header-identity-wrapper  {
						.header-site-slogan  {
							font-size: $font-tiny;
						}
					}
				}
			}
			.block-system-main-menu {
				.block--inner-wrapper {
					.block-content {
						.menu-toggle-button {
							top: calc(50% - 5px);
						}
					}
				}
			}
		}
	}
}

.logged-in {
	.l-header {
		top: 33px;
		&.sticky_header {
			top: 0;
		}
	}
	&.crm-menubar-visible {
		.l-header {
			top: 40px;
			&.sticky_header {
				top: 0;
			}
		}
	}
	@include md {
		&.crm-menubar-visible {
			.l-header {
				top: 33px;
				&.sticky_header {
					top: 0;
				}
			}
		}
	}
}