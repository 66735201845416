.block {
	&.info-block {
		min-height: 500px;
		.block--inner-wrapper {
			column-gap: 66px;
			grid-template-columns: 370px 730px;
			padding: 0 15px;
			.block-title {
				color: $white;
				margin: 0 0 5px;
			}
			.block-content {
				display: block;
				font-size: $font-md;
				article {
					display: block;
					.content {
						display: block;
					}
				}
				h3,
				h4 {
					font-size: $font-md;
					margin: 0;
				}
				h3 {
					line-height: 1.6;
				}
				ul,
				ol {
					margin: 0 0 0 10px;
					padding: 0 0 0 15px;
					li {
						line-height: 1.6;
					}
				}
				a {
					&.button {
						background: no-repeat;
						color: $black !important;
						line-height: 1.6;
						margin: 0;
						padding: 0;
						text-transform: capitalize;
						&:first-child {
							border-right: 2px solid $white;
							padding-left: 0 !important;
							padding-right: 30px;
						}
						&:last-child {
							border: 0;
							padding-left: 30px;
							padding-right: 0;
						}
					}
				}
				#node-10 a {
					&.button {
						color: $white !important;
					}
				}
				.view-header {
					margin-bottom: 20px;
				}
			}
		}
	}
	@include lg {
		&.info-block {
			.block--inner-wrapper {
				grid-template-columns: 30% calc(70% - 66px);
			}
		}
	}
	@include md {
		&.info-block {
			.block--inner-wrapper {
				display: table;
				.block-title {
					display: inline-block;
					font-size: $font-md;
					line-height: 30px;
					margin-bottom: 15px;
				}
				.block-content {
					font-size: $font-sm;
					line-height: 30px;
					h3 {
						font-size: $font-sm;
						line-height: 30px;
					}
					ul,
					ol {
						li {
							line-height: 30px;
						}
					}
					.content {
						display: inline-block;
						width: 100%;
						.field-name-field-info-block-leader {
							margin-top: -15px;
						}
					}
					a {
						&.button {
							border: 0;
							display: block;
							font-size: $font-x-tiny;
							line-height: 20px;
							padding: 5px 0;
							&:first-child {
								border-right: 0;
								padding-right: 0;
							}
							&:last-child {
								padding-left: 0;
							}
						}
					}
				}
				p {
					font-size: $font-x-tiny;
					line-height: 30px;
				}
			}
		}
	}
}