#node-28 {
	.field-name-body {
		.field-item {
			> hr {
				&:first-child {
					margin-bottom: 45px;
					margin-top: 0;
				}
				&:last-child {
					margin-bottom: 0;
					margin-top: 45px;
				}
			}
		}
	}
	.field-name-body {
		.field-item {
			> hr {
				&:first-child {
					margin-bottom: 15px;
				}
			}
		}
	}
}