// Colors
$alpine			:#BEA331; // Solid backgrounds, including footer
$bay-of-many	:#23408F; // Solid backgrounds, including navbar/header
$black			:#000000;
$carmine		:#B93B3B; // Solid red backgrounds
$gray			:#707070; // dotted lines and arrow heads for accordions
$merino			:#F4F0DE; // Pale gold background for accordions and form fields
$roti			:#C2AD40; // Text on blue background
$reef-gold		:#A3891F; // Gold text on white background
$white			:#FFFFFF;


// Font Size
$font-x-lg	:42px;
$font-lg	:30px;
$font-x-md	:28px;
$font-md	:24px;
$font-x-sm	:22px;
$font-sm	:20px;
$font-x-tiny:18px;
$font-tiny	:16px;

// Media Screen
// Small tablets and large smartphones (landscape view)
$screen-sm-max: 550px;

// Small tablets (portrait view)
$screen-md-max: 767px;

// Tablets and small desktops
$screen-lg-max: 991px;

// Large tablets and desktops
$screen-xl-max: 1199px;
$screen-xxl-max: 1445px;
