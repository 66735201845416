.l-footer {
	background: $alpine;
	border: 0;
	margin-top: 75px;
	padding: 50px 0 60px;
	.block-block-footer {
		padding: 0;
	}
	.block-content {
		h3 {
			font-family: 'Zilla-medium';
			font-size: $font-x-lg;
			margin: 20px 0;
			text-align: center;
			a {
				&:first-child {
					padding-right: 30px;
				}
				&:last-child {
					padding-left: 30px;
				}
			}
		}
		h4 {
			font-size: $font-md;
			text-align: center;
			margin: 0;
		}
		.footer_bottom {
			display: grid;
			grid-template-columns: calc(100% - 163px) 130px 30px;
			column-gap: 15px;
			p {
				font-family: 'Zilla-Semibold';
				font-size: 14px;
				line-height: 20px;
				margin: 0;
				&:nth-child(2) {
					border-left: 1px solid $white;
					border-right: 1px solid $white;
					position: relative;
					text-align: center;
					a {
						font-family: 'Zilla-Light';
						font-size: $font-md;
						left: 0;
						line-height: 36px;
						position: absolute;
						top: calc(50% - 18px);
						width: 100%;
					}
				}
			}
			.icons {
				font-size: 24px;
				line-height: 36px;
			}
		}
	}
	@include lg {
		.block-content {
			.footer_bottom {
				grid-template-columns: calc(100% - 195px) 180px;
			}
		}
	}
	@include md {
		padding: 30px 0 15px;
		.block--inner-wrapper {
			padding: 0;
			.block-content {
				h3 {
					font-size: $font-sm;
					white-space: nowrap;
					a {
						&:first-child {
							padding-right: 15px;
						}
						&:last-child {
							padding-left: 15px;
						}
					}
				}
				h4 {
					font-size: $font-tiny;
					line-height: 20px;
				}
				.footer_bottom {
					display: table;
					p {
						border-bottom: 1px dotted $white;
						padding-bottom: 15px;
						text-align: center;
						a {
							position: static;
						}
						&:last-child {
							border: 0;
							margin-top: 15px;
							padding: 0;
						}
					}
				}
			}
		}
		
	}
}
.front {
	.l-footer {
		margin-top: 0;
	}
}