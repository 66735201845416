.block-views-slideshow-block {
	padding: 0 !important;
	.container {
		max-width: unset;
		padding: 0;
		.view-content {
			.flexslider {
				border: 0;
				margin: 0;
				.slides {
					li {
						height: 500px;
						img {
							height: 500px;
							object-fit: cover;
						}
						@media only screen and (min-width: 1350px) {
							height: auto;
							img {
								height: auto;
							}
						}
					}
					.slider_item_inner {
						&.with_body {
							align-items: center;
							background: $roti;
							display: flex;
							.slider_image {
								float: left;
								width: 50%;
							}
							.slider_body {
								align-items: center;
								display: flex;
								font-family: 'Zilla-Semibold';
								height: 100%;
								justify-content: center;
								padding: 50px;
								text-align: center;
								width: 50%;
								p {
									font-size: 24px;
									line-height: 30px;
									margin: 0 0 15px;
								}
								h2 {
									font-family: 'Zilla-Medium';
									font-size: 54px;
									font-style: italic;
									line-height: 54px;
									margin: 0 0 15px;
								}
								h3 {
									font-family: 'Zilla-Medium';
									font-size: 36px;
									line-height: 42px;
									margin: 0 0 15px;
								}
							}
						}
					}
				}
				.flex-control-nav {
					bottom: 0;
					z-index: 11;
				}
				.flex-direction-nav {
					li {
						a {
							line-height: 1;
						}
					}
				}
			}
		}
	}
	@include md {
		.container {
			.view-content {
				.flexslider {
					.slides {
						li {
							height: auto;
							img {
								height: auto;
							}
						}
						.slider_item_inner {
							&.with_body {
								.slider_body {
									padding: 15px;
									p {
										font-size: 14px;
										line-height: 16px;
										margin: 0 0 5px;
									}
									h2 {
										font-size: 24px;
										line-height: 24px;
										margin: 0 0 5px;
									}
									h3 {
										font-size: 18px;
										line-height: 18px;
										margin: 0 0 5px;
									}
								}
							}
						}
					}
					.flex-control-nav {
						margin: 0;
					}
				}
			}
		}
	}
}