.view-events,
.view-civi-events {
	a {
		color: $reef-gold;
	}
	table {
		font-size: $font-sm;
		line-height: 30px;
		margin-bottom: 0;
		tbody {
			tr {
				border-bottom: 1px solid $black;
				td {
					border: 0;
					padding: 13px;
					vertical-align: top;

					h4,
					p,
					span {
						margin: 0;
					}
					&:first-child {
						max-width: 250px;
						min-width: 180px;
						padding-left: 0;
					}
					&:last-child {
						padding-right: 0;
					}
					&.views-field-registration-link-text {
						font-family: 'Zilla-Semibold';
						width: 140px;
					}
				}
				&:first-child {
					border-top: 1px solid $black;
				}
			}
		}
	}
	.more-link {
		line-height: 36px;
		text-align: left;
	}
	@include md {
		table {
			tbody {
				display: block;
				tr {
					display: flex;
					display: -webkit-flex;
					flex-wrap: wrap;
					padding: 15px 0;
					td {
						display: block;
						font-size: $font-x-tiny;
						padding: 0;
						width: 100%;
						h4 {
							font-family: 'Zilla-Medium';
							font-size: $font-x-tiny !important;
						}
						&.views-field-nothing {
							order: 1;
						}
						&.views-field-start-date {
							order: 2;
						}
						&.views-field-street-address {
							order: 3;
						}
						&.views-field-registration-link-text {
							order: 3;
							width: 100%;
						}
						&:first-child {
							max-width: unset;
						}
					}
				}
			}
		}
	}
}
.front {
	.view-events,
	.view-civi-events {
		table {
			font-size: $font-md;
			margin-bottom: 15px;
		}
	}
}
.front {
	.view-events,
	.view-civi-events {
		a {
			color: $white !important;
		}
	}
}
#crm-main-content-wrapper {
	.event-info {
		.crm-section {
			clear: both;
			font-size: $font-x-sm;
			margin-bottom: 30px;
			text-align: center;
		}
		.event_description-section {
			clear: both;
			text-align: center;
			h1 {
				margin: 15px 0 30px;
				text-align: center;
				font-style: italic;
			}
			h2 {
				margin: 0 0 25px;
				text-align: center;
			}
			hr {
				margin: 25px 0;
			}
			> p {
				text-align: center;
				&:last-child {
					text-align: left;
				}
			}
			table {
				border: 0;
				width: 100% !important;
				tbody {
					tr {
						border-bottom: 2px dotted $gray;
						border-top: 2px dotted $gray;
						td {
							border: 0;
							font-size: $font-x-sm;
							padding: 50px 0;
							text-align: left;
							h3 {
								background: no-repeat;
								font-size: $font-md;
								margin: 0;
								padding: 0;
								text-align: left;
							}
							p {
								font-size: $font-x-sm;
								text-align: left;
								em,
								i {
									display: block;
									font-size: $font-md;
									margin-bottom: 25px;
								}
							}
							img {
								height: auto !important;
								width: 100% !important;
							}
							&:first-child {
								width: 200px;
							}
							&:nth-child(2) {
								padding-left: 30px;
							}
							&:last-child {
								p {
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
		.event_date_time-section,
		.event_fees-section {
			display: none;
		}
		.event_address-section,
		.crm-section,
		> div {
			margin-bottom: 30px;
			text-align: left;
			> .label,
			> .content {
				display: inline-flex;
				float: none;
				font-size: $font-x-sm;
				margin: 0;
				padding: 0;
				width: auto;
			}
			> .label {
				font-weight: normal;
				padding-right: 15px;
			}
			> .content {
				text-align: left;
				table {
					tbody {
						tr {
							td {
								padding-left: 5px;
							}
						}
					}
				}
			}
			@include md {
				> .label {
					float: left;
					width: 100px;
				}
				> .content {
					float: left;
					width: calc(100% - 115px);
				}
			}
		}
		.crm-actionlinks-bottom,
		.crm-actionlinks-top {
			text-align: center;
			> div {
				float: none;
				height: auto;
				margin: 0;
			}
			a {
				background: $bay-of-many;
				border-radius: 0;
				color: $white;
				display: inline-block;
				float: none;
				font-size: $font-md;
				height: auto;
				margin: 0;
				padding: 10px 30px;
				text-transform: inherit;
			}
			@include md {
				a {
					font-size: $font-sm;
				}
			}
		}
		.crm-actionlinks-top {
			display: none;
		}
	}
	@include md {
		.event-info {
			.event_description-section {
				> p {
					&:last-child {
						text-align: center;
					}
				}
				table {
					tbody {
						tr {
							td {
								display: block;
								padding: 30px 0;
								text-align: center;
								width: 100% !important;
								h3,
								p {
									text-align: center;
								}
								&:first-child {
									padding-bottom: 0;
								}
								&:nth-child(2) {
									padding-left: 0;
								}
							}
						}
					}
				}
			}
			.event_date_time-section,
			.event_address-section,
			.crm-section,
			> div {
				text-align: center;
			}
		}
	}
}