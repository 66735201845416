.layout {
	height: auto;
}
.layout-front {
	.block {
		padding: 75px 0;
	}
	.register_now,
	.special_announcement {
		background: $roti;
		padding: 15px 0;
		.block-content {
			color: $black;
			margin: 0;
			.views-row {
				margin: 0;
			}
			h3 {
				font-family: 'Zilla-Light';
				margin: 0;
				text-align: center;
			}
			p {
				margin: 0;
			}
			a {
				color: $white;
			}
		}
	}
	@include md {
		.register_now,
		.special_announcement {
			.block-content {
				h3 {
					font-size: $font-sm;
					line-height: 30px;
				}
				p {
					line-height: 24px;
				}
			}

		}
	}
}

@include md {
	.layout-front .block {
		padding: 35px 10px;
		min-height: none;
	}
	.layout-front .block.special_announcement {
		padding: 15px 0;
	}
	.view-board-meetings table th.views-field {
		display: none;
	}
}
.l-wrapper {
	.field-name-field-hero-image {
		display: none;
	}
	#hero_image {
		.field-name-field-hero-image {
			display: block;
		}
		img {
			object-fit: cover;
			object-position: center;
			width: 100%;
		}
	}
	.civicrm_hero_block {
		margin-left: calc(-50vw + 50%);
		min-height: 450px;
		width: 100vw;
		.block-title {
			text-shadow: none;
		}
	}
	@include lg {
		.civicrm_hero_block {
			margin-left: 0;
		}
	}
	@include md {
		> .field-name-field-hero-image {
			img {
				height: auto;
			}
		}
	}
}
.links_wrapper {
	color: $reef-gold;
	font-size: $font-sm;
	line-height: 30px;
	text-align: center;
	a {
		color: $reef-gold;
		font-family: 'Zilla-Semibold';
		font-size: $font-md;
		padding: 0 15px;
		&:first-child {
			border-right: 2px solid $reef-gold;
		}
		&:last-child {
			border-right: 0;
		}
	}
	@include md {
		a {
			font-size: $font-sm;
			display: block;
			padding: 5px 0;
			&:first-child {
				border-right: 0;
				border-bottom: 1px dotted $reef-gold;
				padding-bottom: 10px;
			}
			&:last-child {
				padding-top: 10px;
			}
		}
	}
}
.type_of_project {
	font-size: $font-x-tiny;
	margin: 0 0 21px;
	span {
		float: right;
		padding-left: 15px;
	}
}

@include md {
	.container-fluid {
		padding-left: 25px;
		padding-right: 25px;
	}
}

/* Special CSS for Spring Benefit Page */

#node-81 {
	td {
		width: 20%;
	}
	@include md {
		td {
			width: 100%;
		}	
	}
	figcaption {
		margin: 10px;
		line-height: 18px;
	}
}

/* Fixing problem with space below page title on Events page */

.block-views-civi-events-block-2 .view-header h3 {
	margin-top: 0;
}