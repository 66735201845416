// import local variables and mixins
@import "abstracts/local-variables";
@import "abstracts/mixins";

// Import fonts
@import "fonts/fonts";

// import base/layout/components etc.
@import "base/base";
@import "base/body";
@import "layout/header";
@import "layout/footer";
@import "components/info-block";
@import "components/forms";
@import "components/menu";
@import "components/accordion";
@import "components/events";
@import "components/slideshow";
@import "components/grants";
@import "components/donate";
@import "components/civicrm";
@import "components/static";
